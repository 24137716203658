import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_DEV !== "development") {
  Sentry.init({
    dsn:
      "https://glet_adaa38bb8a4dd0d16cacb4b7fa17d19e@observe.gitlab.com:443/errortracking/api/v1/projects/18236549",
    integrations: [Sentry.replayIntegration()],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setTag("name", "client");
  console.log("Sentry enabled");
  // Sentry.captureException(new Error("This is my fake error message"));
} else {
  console.log("Sentry disabled");
}
